import React, { useState, useEffect } from "react";

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    console.log("Fetching session status for session_id:", sessionId); // Log the session ID

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => {
        console.log("Response status:", res.status); // Log the response status
        return res.json();
      })
      .then((data) => {
        console.log("Fetched data:", data); // Log the fetched data
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      })
      .catch((error) => {
        console.error("Error fetching session status:", error); // Log any errors
      });
  }, []);

  console.log("Current status:", status); // Log the current status

  if (status === 'open') {
    console.log("Status is open");
    return (
      <section id="open-status">
        <p>Your session is still open. Please complete your checkout.</p>
      </section>
    );
  }

  if (status === 'complete') {
    console.log("Status is complete");
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return <p>Loading...</p>;
};

export default Return;