import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51QNfLCGHWGpSCVAJCc7I6ma6cfYKomzU5bVVn9RS573BJbyzwGUtQV2VNjvD4lLwia7oYHXB8vf4FewL1maaw5nx00SeJK9gnn");

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');
  const domain = "https://api.templatetrainer.com";
  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await fetch(domain+"/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error || "Failed to create checkout session";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  }, []);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const options = { clientSecret };

  return (
    <div id="checkout">
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CheckoutForm;