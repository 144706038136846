import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faStopCircle
} from "@fortawesome/free-solid-svg-icons";
// Import your audio file for ending sound;
import CountdownSound from "../assets/audio/ThreeTwoOne.mp3";
import GetItSound from "../assets/audio/Go.mp3";
// TODO: Deal with linter warnings about useReducer for state dependent functions in useEffect

export default function RestTimer(props) {
  // component state
  const [active, setActive] = useState(false);
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(Date.now());
  const [soundsPlayed, setSoundsPlayed] = useState(false);

  // init state here and setup timer
  useEffect(
    () => {
      let timer = setTimeout(() => {
        // this is in seconds
        let timeElapsed = start === 0 ? 0 : (Date.now() - start) / 1000;
        let timeRemaining = props.specificExercise.sets[props.specificSet].rest - timeElapsed;
        if (timeRemaining > 0.5) {
          setTime(timeRemaining);
          // Check for countdown sound effects
          if (Math.floor(timeRemaining) === 3 && !soundsPlayed) {
            setSoundsPlayed((prevSoundsPlayed) => {
              if (!prevSoundsPlayed) {
                playSounds();
                setSoundsPlayed(true);
              }
              return true;
            });
          }
        }
        else {
          setTime(0);
          setStart(0);
          setActive(false);
          props.showTimer(false);
        }
      }, 120);
      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer);
        console.log("Timeout Cleared");
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than will run every time
    // this value changes (useEffect re-run)
    [active, time, start, props.specificSet]
  );

  const playSounds = () => {
    const countdownAudio = new Audio(CountdownSound);
    const getItAudio = new Audio(GetItSound);

    countdownAudio.play();
    countdownAudio.onended = () => {
      getItAudio.play();
    };
  };

  function formatTime() {
    let status = "";
    // Calculate current hours, minutes, and seconds
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = Math.floor(time % 60)

    // Display a leading zero if the values are less than ten
    let displayHours = (hours < 10) ? '0' + hours : hours;
    let displayMinutes = (minutes < 10) ? '0' + minutes : minutes;
    let displaySeconds = (seconds < 10) ? '0' + seconds : seconds;

    // Write the current stopwatch display time into the display paragraph
    return (status + displayHours + ':' + displayMinutes + ':' + displaySeconds);
  }

  return (
    <div id="restTimer" style={{ backgroundColor: 'white' }}><h3>Rest {formatTime()}</h3>
    <button   style={{
    backgroundColor: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    margin: "0 10px"}}
    id="cancel" onClick={() => props.showTimer(false)}> 
    <FontAwesomeIcon icon={faStopCircle} /> 
    </button>
    </div>
  )
}
